import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PortableText } from '@portabletext/react';
import { Link as GatsbyLink } from 'gatsby';
import Modal from 'react-modal';
import PartnerDetailsForm from './PartnerDetailForm';

const PartnerContainer = styled.div`
  width: 388px;
  height: 600px;
  gap: 36px;
  border-radius: 24px;
  border: 1px solid var(--neutral-gray-200-strokes, #d0d0d0);
  background: ${props => (props.isDetails ? 'var(--primary-blue-200, #D3F0F6)' : 'var(--shade-white-0, #fff)')};
  box-shadow: 0px 20px 20px -14px rgba(161, 161, 161, 0.16);

  @media (max-width: 952px) {
    height: 450px;
  }

  @media (max-width: 397px) {
    max-width: 315px;
  }
`;
const ImageContainer = styled.div`
  width: 388px;
  height: 200px;
  background: url(${props => props.image}) no-repeat center center;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  @media (max-width: 397px) {
    max-width: 315px;
  }
`;
const ExcerptContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 28px 0px 24px;

  @media (max-width: 952px) {
    padding: 14px 18px;
  }
`;
const TextContent = styled.div`
  max-height: 100%;
  h1,
  h2,
  h3,
  h4,
  p {
    font-family: 'Rubik';
    font-style: normal;
    letter-spacing: -0.5px;
    color: #202323;

    @media (max-width: 952px) {
      margin-bottom: 10px;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-size: 28px;
    line-height: 38px;
    font-weight: 400;
    margin-top: 24px;
    margin-bottom: 24px;

    @media (max-width: 952px) {
      font-size: 22px;
      line-height: 30px;
      font-weight: 300;
      margin-top: 14px;
      margin-bottom: 14px;
    }
  }

  p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 24px;

    @media (max-width: 952px) {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0px;
    }
  }
`;
const Title = styled.h4`
  margin-top: 24px;
  margin-bottom: 24px;
  color: var(--neutral-gray-900, #3b4b53);
  font-size: 28px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  line-height: 38px;
  letter-spacing: -0.5px;
`;
const CategoryContainer = styled.div`
  display: flex;
  width: fit-content;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: var(--primary-blue-200, #d3f0f6);
  margin: 0px 28px;
`;
const CategoryText = styled.p`
  color: var(--neutral-gray-900, #3b4b53);
  font-size: 18px;
  font-family: 'Rubik';
  line-height: 28px;
  letter-spacing: -0.5px;
  margin: 0px;
`;
const SpecialOfferButton = styled.button`
  display: flex;
  align-self: flex-end;
  height: 48px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 0px;
  background: var(--primary-blue-500-main, #00a5c8);
  color: var(--shade-white-0, #fff);
  font-size: 16px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 952px) {
    margin-bottom: 20px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 20%;
  padding-top: 20px;
`;

const PartnerCard = ({ partnerProp, isDetails }) => {
  const [partner, setPartner] = useState();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setPartner(partnerProp);
  }, [partnerProp]);

  return (
    <>
      {isDetails ? (
        <PartnerContainer isDetails={isDetails}>
          <ImageContainer image={partner?.logo.asset.url} />
          <ExcerptContainer>
            <TextContent>
              <Title>Special Offer</Title>
              <PortableText value={partner?.specialOfferDetailed} />
            </TextContent>
          </ExcerptContainer>
          <Modal
            isOpen={isOpen}
            contentLabel='Visitor Benefit'
            onAfterClose={() => setOpen(false)}
            overlayClassName='overlay-join-partners-modal'
            className='join-partners-modal'
            ariaHideApp={false}>
            <PartnerDetailsForm setOpen={setOpen} />
          </Modal>
          <ButtonContainer>
            <SpecialOfferButton onClick={() => setOpen(true)}>More information</SpecialOfferButton>
          </ButtonContainer>
        </PartnerContainer>
      ) : (
        <GatsbyLink to={`/partners/partner/${partner?.slug?.current}`}>
          <PartnerContainer isDetails={isDetails}>
            <ImageContainer image={partner?.logo.asset.url} />
            <ExcerptContainer>
              <TextContent>
                <PortableText value={partner?.specialOfferTitle} />
                <PortableText value={partner?.specialOfferText} />
              </TextContent>
            </ExcerptContainer>
            <CategoryContainer>
              <CategoryText>{partner?.category?.categoryName}</CategoryText>
            </CategoryContainer>
          </PartnerContainer>
        </GatsbyLink>
      )}
    </>
  );
};

export default PartnerCard;
