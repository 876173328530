import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

const ImageContainer = styled.div`
  height: 364px;
  width: 100%;
  background: url(${props => props.image ?? ''}) no-repeat top;
  background-size: 100%;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1250px) {
    max-height: 280px;
  }

  @media (max-width: 800px) {
    max-height: 300px;
    margin-bottom: 70px;
  }
`;
const HeroTitle = styled.h1`
  color: var(--shade-white-0, #fff);
  text-align: center;
  font-size: 60px;
  font-family: 'Rubik';
  line-height: 68px;
  letter-spacing: -1px;
  font-weight: 400;
  margin: 10px;

  @media (max-width: 1020px) {
    font-size: 40px;
    line-height: 50px;
    margin: 0px;
  }
`;
const HeroSubtitle = styled.h3`
  width: 70%;
  color: var(--shade-white-0, #fff);
  text-align: center;
  font-size: 30px;
  font-family: 'Rubik';
  line-height: 38px;
  letter-spacing: -0.5px;
  font-weight: 300;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 1315px) {
    width: 90%;
  }

  @media (max-width: 1020px) {
    width: 95%;
    font-size: 20px;
    line-height: 26px;
    margin-top: 10px;
  }
`;

const PartnersHero = () => {
  const [windowWidth, setWindowWidth] = useState();
  const [bannerImage, setBannerImage] = useState();

  const { desktop, mobile } = useStaticQuery(graphql`
    {
      desktop: file(relativePath: { eq: "images/partners/Hero.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
      mobile: file(relativePath: { eq: "images/partners/Hero_mbl.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
    }
  `);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setBannerImage(windowWidth >= 866 ? desktop : mobile);
  }, [windowWidth]);

  return (
    <ImageContainer image={bannerImage?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}>
      <HeroTitle>
        Discover <strong>our partners</strong>
      </HeroTitle>
      <HeroSubtitle>
        Explore our selection of reliable partner apps, solutions, and integrations designed to boost team productivity,
        enhance the employee onboarding experience, and unleash even more possibilities.
      </HeroSubtitle>
    </ImageContainer>
  );
};

export default PartnersHero;
