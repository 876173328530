import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ModalContent } from 'react-st-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHSFormsScriptLoaded } from '../../helpers/hooks';
import Spinner from '../spinner/Spinner';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const IconContainer = styled.div`
  width: fit-content;
  cursor: pointer;
`;

const PartnersForm = styled.div`
  margin-left: 15px;
  order: 2;

  @media (max-width: 576px) {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    order: 1;
  }
`;

const SpinnerContainer = styled.div`
  width: fit-content;
  margin: auto;
`;

const PartnerDetailsForm = ({ setOpen }) => {
  const HSFormsScriptLoaded = useHSFormsScriptLoaded();
  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    if (HSFormsScriptLoaded) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '14573977',
        formId: '36bcbec1-9a95-486e-a3bd-8b7713ed6f02',
        target: '#partner-details-form',
        onFormReady: () => {
          setFormLoading(false);
        },
      });
    }
  }, [HSFormsScriptLoaded]);

  return (
    <ModalContent>
      <ButtonContainer>
        <IconContainer>
          <FontAwesomeIcon icon={faTimes} style={{ color: '#000000' }} onClick={() => setOpen(false)} />
        </IconContainer>
      </ButtonContainer>
      {formLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      <PartnersForm id='partner-details-form' className='contact-form' data-hs-forms-root='true' />
    </ModalContent>
  );
};

export default PartnerDetailsForm;
