import React from 'react';
import { Box } from 'rebass';
import Layout from '../../components/layout/Layout';
import MetaData from '../../components/MetaData';
import PartnersHero from '../../components/partners/PartnersHero';
import PartnerGrid from '../../components/partners/PartnerGrid';
import JoinPartner from '../../components/partners/JoinPartner';

const PartnersHub = () => {
  return (
    <>
      <MetaData title='Nexton Partners' />
      <Layout selected='resources'>
        <Box className='full-width'>
          <PartnersHero />
          <PartnerGrid />
          <JoinPartner />
        </Box>
      </Layout>
    </>
  );
};

export default PartnersHub;
