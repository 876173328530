import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import JoinPartnerForm from './JoinPartnerForm';

const MainContainer = styled.div`
  background: var(--primary-blue-200, #d3f0f6);
  box-shadow: 0px 41px 60px 11px rgba(161, 161, 161, 0.160047);
  width: 100%;
  height: fit-content;
  margin-top: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 90px;

  @media (max-width: 1260px) {
    padding: 48px 20px;
  }
`;
const Title = styled.h2`
  color: var(--primary-blue-500-main, #00a5c8);
  text-align: center;
  font-size: 42px;
  font-family: 'Rubik';
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1px;
  margin: 0px;
`;
const Subtitle = styled.h4`
  width: 50%;
  color: var(--shade-black-900, #202323);
  text-align: center;
  font-size: 20px;
  font-family: 'Rubik';
  line-height: 30px;
  letter-spacing: -0.5px;
  font-weight: 400;

  @media (max-width: 1260px) {
    width: 60%;
  }

  @media (max-width: 675px) {
    width: 100%;
  }
`;
const JoinButton = styled.button`
  display: flex;
  height: 56px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: white;
  background: var(--gradient-btn-1-active, linear-gradient(90deg, #00a5c8 0%, #01c8ef 100%));
  color: var(--shade-white-0, #fff);
  font-size: 16px;
  font-family: 'Rubik';
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  cursor: pointer;
`;

const JoinPartner = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <MainContainer>
      <Title>Get in contact with us</Title>
      <Subtitle>
        If you’re looking to establish a referral partnership that can drive more leads for your sales team, we should
        definitely connect! Let’s have a conversation and see how we can make it happen.
      </Subtitle>
      <Modal
        isOpen={isOpen}
        contentLabel='Join Partners Form'
        onAfterClose={() => setOpen(false)}
        overlayClassName='overlay-join-partners-modal'
        className='join-partners-modal'
        ariaHideApp={false}>
        <JoinPartnerForm setOpen={setOpen} />
      </Modal>
      <JoinButton onClick={() => setOpen(true)}>Join us now!</JoinButton>
    </MainContainer>
  );
};

export default JoinPartner;
