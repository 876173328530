import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import PartnerCard from './PartnerCard';

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 80px;
  width: 100%;
  justify-content: center;
  margin-top: 144px;

  @media (max-width: 1400px) {
    grid-gap: 39px;
    margin-top: 0px;
  }
  @media (max-width: 1260px) {
    grid-template-columns: repeat(2, auto);
  }
  @media (max-width: 820px) {
    display: flex;
    flex-direction: column;
  }
`;

const PartnerContainer = styled.div`
  align-self: center;
`;

const PartnerGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityPartner(limit: 9, sort: { fields: _createdAt, order: DESC }) {
        nodes {
          slug {
            _type
            _key
            current
            source
          }
          specialOfferTitle {
            _key
            _rawChildren
            _type
            list
            children {
              _key
              _type
              text
              marks
            }
            style
          }
          specialOfferText {
            _key
            _rawChildren
            _type
            list
            children {
              _key
              _type
              text
              marks
            }
            style
          }
          id
          category {
            categoryName
          }
          logo {
            asset {
              url
            }
          }
        }
      }
    }
  `);

  const [partners, setPartners] = useState([]);

  useEffect(() => {
    setPartners(data.allSanityPartner.nodes);
  }, [data]);

  return (
    <MainContainer>
      {partners.map(partner => (
        <PartnerContainer>
          <PartnerCard partnerProp={partner} isDetails={false} />
        </PartnerContainer>
      ))}
    </MainContainer>
  );
};

export default PartnerGrid;
